import { createApp } from 'vue'

import { ModuleRegistry, provideGlobalGridOptions } from 'ag-grid-community';
import { AllEnterpriseModule, LicenseManager, SparklinesModule } from 'ag-grid-enterprise';
import { AgChartsCommunityModule } from 'ag-charts-community';

LicenseManager.setLicenseKey('Using_this_{AG_Grid}_Enterprise_key_{AG-066616}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Logicly_Pty_Ltd}_is_granted_a_{Multiple_Applications}_Developer_License_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{2}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{28_July_2025}____[v3]_[01]_MTc1MzY1NzIwMDAwMA==9f22830807d3acb7ef1f07c6e0306007');


// Register all enterprise features
ModuleRegistry.registerModules([
  AllEnterpriseModule,
  SparklinesModule.with(AgChartsCommunityModule),
]);

// Mark all grids as using legacy themes
provideGlobalGridOptions({ theme: "legacy"});

import App from './App.vue'

import './assets/tailwind.css'
const app = createApp(App);

import { createPinia } from 'pinia';
const pinia = createPinia();
app.use(pinia);

import Router from './router.js';
app.use(Router);

app.mount('#app');
